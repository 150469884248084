'use client';

import { useRef, type FC, useEffect } from 'react';
import type { AnalyticsData } from '@/types/entities';
import { useSiteContext } from '@/context/SiteContext';
import { useAdService } from '@/context/AdServiceContext';
import { useGaContext } from '@/context/GoogleAnalyticsContext';

export const AnalyticsPageView: FC<{ data: AnalyticsData }> = ({ data }) => {
	const canSendPageView = useRef(true);
	const { sendGaEvent } = useGaContext();
	const { gaVars } = useSiteContext();
	const adService = useAdService();
	const isArticlePage = /article/.test(data?.post?.meta?.page_type ?? '');

	useEffect(() => {
		if (canSendPageView.current) {
			const analyticsData = {
				sendTo: gaVars.platformTracker,
				...data,
			};
			if (!isArticlePage) {
				adService.unsetContinuousScrollCorrelator();
			}
			sendGaEvent('page_view', analyticsData);
			canSendPageView.current = false;
		}
	}, [adService, isArticlePage, data, sendGaEvent, gaVars.platformTracker]);

	return null;
};
