'use client';

import { useState, useEffect } from 'react';
import type { AppSettings } from '@/utils/data/fetch-app-settings';

export const useFetchAppSettings = () => {
	const [appSettings, setAppSettings] = useState<AppSettings | null>(null);

	useEffect(() => {
		const fetchAppSettings = async () => {
			const settings = await fetch('/.api/app-settings')
				.then((response) => (response.ok ? response.json() : Promise.reject()))
				.catch(() => null);

			setAppSettings(settings);
		};

		fetchAppSettings();
	}, []);

	return appSettings;
};
