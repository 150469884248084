import Image from 'next/image';
import type { FC, PropsWithChildren } from 'react';

import styles from './styles.module.css';

interface CustomErrorProps extends PropsWithChildren {
	errorPageCode?: string;
	errorPageTitle: string;
	image?: string;
}

export const CustomError: FC<CustomErrorProps> = ({
	children,
	errorPageCode,
	errorPageTitle,
	image,
}) => (
	<div className={styles.customError}>
		{image ? (
			<figure className={styles.customErrorImageWrapper}>
				<Image
					alt=""
					className={styles.customErrorImage}
					height={600}
					priority
					src={image}
					width={1600}
				/>
			</figure>
		) : null}
		<div className={styles.customErrorContent}>
			<h1 className={styles.customErrorTitle}>
				{errorPageCode && (
					<span className={styles.customErrorCode}>{errorPageCode}</span>
				)}
				{errorPageTitle}
			</h1>
			{children}
		</div>
	</div>
);
