'use client';

import { Sticky } from '@/components/global/Sticky';
import { CustomError } from '@/components/CustomError';
import { Header } from '@/components/raven/global/Header';
import { Footer } from '@/components/raven/global/Footer';
import { stubPageType } from '@/utils/analytics/google-analytics';
import type { AppSettings } from '@/utils/data/fetch-app-settings';
import { AnalyticsPageView } from '@/components/AnalyticsPageView';
import { useFetchAppSettings } from '@/utils/hooks/use-fetch-app-settings';

export default function Error({ reset }: { reset: () => void }) {
	const appSettings = useFetchAppSettings();
	const analyticsData = stubPageType('other');

	const menus = appSettings?.menus || {};
	const settings = (appSettings?.settings as AppSettings['settings']) || {};

	return (
		<>
			<AnalyticsPageView data={analyticsData} />
			<Sticky>
				<Header menus={menus} settings={settings} />
			</Sticky>
			<main className="error-page" id="main">
				<CustomError
					errorPageCode=""
					errorPageTitle="Oops! Something went wrong"
					image={settings?.error_page_image}
				>
					<p>There has been a problem loading this page.</p>
					<button
						className="button button--outline"
						onClick={() => reset()}
						type="button"
					>
						Try again
					</button>
				</CustomError>

				<Footer
					copyright={settings?.copyright}
					logo={settings?.footer_logo}
					menus={menus}
				/>
			</main>
		</>
	);
}
