import type { AnalyticsData } from '@/types/entities';

export function stubPageType(page_type: string): AnalyticsData {
	return {
		post: {
			meta: {
				page_type,
			},
		},
	};
}
